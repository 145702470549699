<script>
import { useNav } from 'src/lib/nav.js'
import { ref, computed, watch, onMounted } from 'vue'
import { t } from 'src/lib/i18n.js'
import { useApp } from 'src/lib/app.js'
import { useAuth } from 'src/lib/auth.js'
import { toDataInfo } from 'src/lib/util'

export default {
  name: 'NavLink',
  props: {
    link: { type: Object, default: null },
    single: Boolean,
    dense: Boolean,
    separator: Boolean
  },
  setup (props) {
    const open = ref(false)
    const app = useApp()
    const auth = useAuth()
    const nav = useNav()
    const client = ref(false)
    const path = computed(() => nav.path)
    const target = computed(() => props.link.href?.includes('://') ? '_blank' : null)
    const children = computed(() => (props.link.children || []).filter(x => x.visible !== false))
    const title = computed(() => props.link.titleKey ? t(props.link.titleKey) : props.link.title)
    const caption = computed(() => props.link.captionKey ? t(props.link.captionKey) : props.link.caption)

    watch(path, (path, oldPath) => {
      // For some reason, the content is hidden if this happens synchronously.
      setTimeout(() => {
        if (path.value?.startsWith(props.link.to)) {
          open.value = true
        } else if (oldPath && oldPath.startsWith(props.link.to)) {
          open.value = false
        }
      })
    }, { immediate: true })

    onMounted(() => {
      client.value = true
    })

    return {
      open,
      target,
      title,
      caption,
      children,
      path,
      props,
      app,
      auth,
      client,
      toDataInfo
    }
  }
}
</script>
<template>
  <div :data-info="toDataInfo(title)" class="NavLink" v-if="!link.if || (client && link.if({auth, app}) === true)">
    <q-expansion-item
      tag="a"
      :label="title"
      :caption="caption"
      :icon="link.icon"
      :href="link.href"
      :to="link.to"
      :target="link.target"
      :exact="link.exact || false"
      :dense="dense"
      v-if="!single && children.length"
      expand-separator
      expand-icon-toggle
      v-model="open"
      :content-inset-level="0.25"
      class="navlink"
      active-class="navlink-active"
      exact-active-class="navlink-exact-active"
    >
      <template #header>
        <q-item-section avatar v-if="link.icon">
          <q-icon size="xs" :name="link.icon" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ title }}</q-item-label>
          <q-item-label caption v-if="!dense && caption">
            {{ caption }}
          </q-item-label>
        </q-item-section>
      </template>
      <q-separator v-if="separator" />
      <NavLink v-for="(l, index) in children" :key="index" :link="l" :dense="dense" />
    </q-expansion-item>
    <q-item
      v-if="single || !children.length"
      :href="link.href"
      :to="link.to"
      :exact="link.exact || false"
      :dense="dense"
      class="navlink"
      active-class="navlink-active"
      exact-active-class="navlink-exact-active"
    >
      <q-item-section avatar v-if="link.icon">
        <q-icon size="xs" :name="link.icon" />
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ title }}</q-item-label>
        <q-item-label caption v-if="!dense && caption">
          {{ caption }}
        </q-item-label>
      </q-item-section>
      <q-item-section side v-if="link.badge">
        <q-badge v-if="link.badge" color="primary" class="q-mr-lg">
          {{ link.badge }}
        </q-badge>
      </q-item-section>
    </q-item>
    <q-separator v-if="separator" />
  </div>
</template>
<style lang="scss">
  .navlink {
    border-left: solid 3px transparent;
    .q-item__section--avatar {
      min-width: 30px;
    }
  }
  .navlink-active {
    color: $primary;
  }
  .navlink-exact-active {
    border-left: solid 4px $primary;
  }
</style>
