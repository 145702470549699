<script>
import UserAvatar from 'components/UserAvatar.vue'
import NavLink from 'components/NavLink.vue'
import { settings, applications } from 'src/router/navigation.js'
import { alterUrl } from 'src/lib/util.js'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuth } from 'src/lib/auth.js'
import { t } from 'src/lib/i18n.js'

export default {
  components: { UserAvatar, NavLink },
  setup () {
    const route = useRoute()
    const auth = useAuth()

    const purge = () => {
      if (typeof localStorage !== 'undefined') {
        Object.keys(localStorage).forEach(x => localStorage.removeItem(x))
      }
      location.reload()
    }
    const loginLink = computed(() => {
      const redirect = route.fullPath
      if (redirect && redirect !== '/') {
        return alterUrl('/login', { query: { redirect } })
      } else {
        return '/login'
      }
    })

    const signUpLink = computed(() => {
      const redirect = route.fullPath
      if (redirect && redirect !== '/') {
        return alterUrl('/login/sign-up', { query: { redirect } })
      } else {
        return '/login/sign-up'
      }
    })

    const redirectUrl = computed(() => {
      if (process.env.CLIENT) {
        return new URL('/login/callback/google', window.location.origin).toString()
      } else {
        return '/login/callback/google'
      }
    })

    return {
      auth,
      loginLink,
      signUpLink,
      redirectUrl,
      purge,
      t,
      settings,
      applications
    }
  }
}
</script>
<template>
  <div class="UserMenu" data-info="avatar-icon">
    <q-skeleton v-if="!auth.initialized" type="QAvatar" />
    <q-btn
      no-caps
      color="primary"
      outline
      :to="loginLink"
      v-if="auth.initialized && !auth.loggedIn"
      class="q-mr-sm"
    >
      {{ t('message.signIn') }}
    </q-btn>
    <q-btn
      no-caps
      color="primary"
      :to="signUpLink"
      v-if="auth.initialized && !auth.loggedIn"
      class="gt-sm"
    >
      {{ t('message.signUp') }}
    </q-btn>

    <q-btn color="primary" round v-if="auth.initialized && auth.loggedIn && auth.profile" class="q-pa-none">
      <UserAvatar :user="auth.profile" size="42px" />
      <q-menu anchor="bottom right" self="top right">
        <div class="row no-wrap q-pa-md UserMenu-Popup" data-info="my-account-menu-container" >
          <div class="column">
            <div class="text-h6 q-mb-md">
              {{ t('message.youAreLoggedIn') }}
            </div>
            <q-list>
              <NavLink single :link="link" v-for="(link, i) in settings.children" :key="i" />
            </q-list>
          </div>

          <q-separator vertical inset class="q-mx-lg" />

          <div class="column items-center">
            <UserAvatar :user="auth.profile" size="72px" />

            <span class="text-subtitle1 q-mt-md q-mb-xs">{{ auth.profile?.renderName }}</span>

            <q-btn
              color="warning"
              label="Logout"
              push
              size="sm"
              v-close-popup
              @click="auth.logout()"
            />
          </div>
        </div>
      </q-menu>
    </q-btn>
  </div>
</template>
